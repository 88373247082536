import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import AuthUserContext from '../auth/AuthUserContext';
import { auth } from '../firebase';
// import Avatar from 'react-avatar';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const MIN_WIDTH_ALLOWED = 960;

class Navbar extends Component {

  constructor() {
    super();

    this.state = {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    };

    this.resizeListener = this.resizeListener.bind(this);
  }

  componentWillUnmount() {
    // remove resize listener
    window.removeEventListener('resize', this.resizeListener);
  }

  componentDidMount() {
    // set resize listener
    window.addEventListener('resize', this.resizeListener);
  }

  toggleOffcanvas() {
    // document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    // console.log("body")
    document.querySelector('body').classList.toggle('offcanvas-active');
    // console.log("offcanvas")
    // console.log(document.querySelector('.offcanvas'))
    document.querySelector('.offcanvas').classList.toggle('show');
    // console.log("screen-overlay")
    document.querySelector('.screen-overlay').classList.toggle('show');
  }

  signOut(evt) {
    auth.doSignOut();
    evt.preventDefault();
  }

  isTeacher() {
    const {
      authData
    } = this.props;

    const studentClass = authData && authData.userData && authData.userData["turma"];

    return (studentClass === "Professor");
  }

  isPathActive(path) {
    return this.props.location && this.props.location.pathname === (path);
  }

  resizeListener = () => {
    this.setState({
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    });
  };

  async onClickPageButton(event, pageNum) {
    // const { setPage } = this.props;

    // console.log(" >>>> click menu: isdirty = " + this.state.isDirty)

    if (this.state.selectedTarget && (this.state.selectedTarget.nodeName === "INPUT" || this.state.selectedTarget.isContentEditable) ) {
      this.state.selectedTarget.blur();
    }

    this.setState({ selectedTarget: null, selectedCategory: -1, canScaleAndRotate: true });
    
    if (this.state.isDirty) {
      event.preventDefault();
      // this.closeMenu();
      
      const optionsConfirmModal = {
        title: 'ImaginaKIDS',
        message: 'Existem alterações não salvas. Você tem certeza que gostaria de continuar?',
        buttons: [
          {
            label: 'Ok',
            onClick: async () => {
              // setPage(pageNum);
              // setDirtyStory(false);
              this.setState({ pageNum, isDirty: false });
              
              // await this.savePage();
              
              this.props.history.push(`/book/${this.state.studentId}/${pageNum}`);
            }
          },
          {
            label: 'Cancelar',
            onClick: () => { }
          }
        ],
        childrenElement: () => <div />,
        // customUI: ({ onClose }) => <div>Custom UI</div>,
        closeOnEscape: false,
        closeOnClickOutside: false,
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {}
      };
      
      confirmAlert(optionsConfirmModal);
    } else {
      // setPage(pageNum);
      this.setState({ pageNum });
      // this.closeMenu();
    }
  }

  render () { 
    const {
      authData
    } = this.props;

    const { studentId } = this.props.match.params;

    const schoolId = authData && authData.userData && authData.userData["codEscola"];
    const classId = authData && authData.userData && authData.userData["turma"];
    const teacherClassId = authData && authData.userData && authData.userData["turmaProfessor"];

    return (
        <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <img src={require("../assets/images/logo.svg")} style={{ height: "80%", float: "left", position: "relative", margin: "10px 15px 10px 10px", }} alt="logo" />
          <a className="navbar-brand align-self-center d-none" href="!#" style={{ backgroundColor: "green" }} onClick={evt =>evt.preventDefault()}>
            {/* <img src={require("../assets/images/logo-mini.svg")} alt="logo" /> */}
            <div className="profile-image">
              <img src={`https://firebasestorage.googleapis.com/v0/b/startupweekend-175921.appspot.com/o/logos%2F${schoolId}.png?alt=media`} alt="profile" />
            </div>
          </a>
            {/* <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => this.toggleOffcanvas() }>
              <i className="mdi mdi-menu"></i>
            </button> */}
            {/* <small className="designation text-small"> */}
            {/* <div>
              {
                  this.isTeacher() && window.location.pathname.indexOf("/book") >= 0 &&
                  <Link style={{ height: "100%", padding: 5, color: "white" }} to={`/catalog`}>                   
                    <i className="mdi mdi-chevron-double-left"></i> Voltar para turma
                  </Link>
              }
            </div> */}
            {
              studentId && this.state.innerWidth >= MIN_WIDTH_ALLOWED ?
              <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <div className="d-flex justify-content-center align-items-center p-8">
                  <Link className={ this.isPathActive(`/book/${studentId}/0`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/0`}>Capa</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/1`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/1`}>1</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/2`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/2`}>2</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/3`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/3`}>3</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/4`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/4`}>4</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/5`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/5`}>5</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/6`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/6`}>6</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/7`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/7`}>7</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/8`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/8`}>8</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/9`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/9`}>9</Link>
                  <Link className={ this.isPathActive(`/book/${studentId}/10`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/10`} >10</Link>
                  {
                    schoolId === "escola4" && classId && (classId.startsWith("5F") || (classId === "Professor" && teacherClassId && teacherClassId.startsWith("5F")) ) &&
                    <>
                      <Link className={ this.isPathActive(`/book/${studentId}/11`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/11`}>11</Link>
                      <Link className={ this.isPathActive(`/book/${studentId}/12`) ? 'btn btn-outline-primary shadow-none mx-1 active' : 'btn btn-outline-primary shadow-none mx-1' } to={`/book/${studentId}/12`} >12</Link>
                    </>
                  }
                </div>
                </div>
                :
                <div style={{ flex: 1, backgroundColor: "red" }}>
                <div className="d-flex p-8" style={{ width: "100%", backgroundColor: "red" }}/>
                </div>
            }
            {/* <ul className="navbar-nav navbar-nav-left header-links">
              <li className="nav-item d-none d-md-flex">
                <a href="!#" onClick={evt =>evt.preventDefault()} className="nav-link">Schedule <span className="badge badge-primary ml-1">New</span>
                </a>
              </li>
              <li className="nav-item active d-none d-xl-flex">
                <a href="!#" onClick={evt =>evt.preventDefault()} className="nav-link">
                  <i className="mdi mdi-elevation-rise"></i>Reports</a>
              </li>
              <li className="nav-item d-none d-lg-flex">
                <a href="!#" onClick={evt =>evt.preventDefault()} className="nav-link">
                  <i className="mdi mdi-bookmark-plus-outline"></i>Score</a>
              </li>
            </ul> */}
            {/* {
              this.isTeacher() &&
                <div className="navbar-nav navbar-nav-right ml-lg-auto" style={{ backgroundColor: "red", height: "80%" }}>
                  <img src={require("../assets/images/btnReview.png")} style={{ height: "100%" }} alt="Revisão de histórias" />
                </div>
            } */}
            <div className="navbar-nav navbar-nav-right ml-lg-auto" style={{ height: "100%" }}>
              {/* {
                this.isTeacher() &&
                <Link style={{ height: "100%", padding: 5 }} to={`/catalog`}>
                  <img src={require("../assets/images/btnReview.png")} style={{ height: "100%" }} alt="Revisão de histórias" />
                </Link>
              } */}
            <ul className="ml-lg-auto" style={{ height: "100%" }}>
              <li className="nav-item  nav-profile border-0" style={{ height: "100%" }}>
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link bg-transparent"> { /* count-indicator */ }
                    <span className="profile-text">{authData && authData.userData && authData.userData.nome}</span>
                    <img className="img-xs rounded-circle" src={`https://firebasestorage.googleapis.com/v0/b/startupweekend-175921.appspot.com/o/logos%2F${schoolId}.png?alt=media`} alt="Profile" />
                    {/* <Avatar color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])} size="32" round={true} name={authData && authData.userData && authData.userData.nome} /> */}

                  </Dropdown.Toggle>
                  <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                    {/* <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom" href="!#" onClick={evt =>evt.preventDefault()}>
                      <div className="d-flex">
                        <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                          <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                        </div>
                        <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                          <i className="mdi mdi-account-outline mr-0"></i>
                        </div>
                        <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                          <i className="mdi mdi-alarm-check mr-0"></i>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0 mt-2" onClick={evt =>evt.preventDefault()}>
                      Manage Accounts
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={evt =>evt.preventDefault()}>
                    Change Password 
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={evt =>evt.preventDefault()}>
                      Check Inbox
                    </Dropdown.Item> */}
                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.signOut}>
                      Sair
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            </div>
            {/* <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
              <span className="mdi mdi-menu"></span>
            </button> */}
          </div>
        </nav>
      //   }
      // </AuthUserContext.Consumer>
    );
  }
}

export default Navbar;
