import React from 'react';

import AuthUserContext from '../AuthUserContext';
import { firebase } from '../../firebase';

const withAuthentication = (Component) =>
  class WithAuthentication extends React.Component {
    constructor(props) {
      super();

      this.state = {
        authUser: null,
        userData: null,
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged(async (authUser) => {
        // console.log(authUser);

        if (authUser != null) {
          // console.log('acesso ao banco');
          const snapshot = await firebase.db.ref('cadastroWeb').orderByChild('email').equalTo(authUser.email).limitToLast(1).once('value');

          // console.log('lendo dados');

          const val = snapshot.val();

          // console.log(val);

          if (val) {
            const key = Object.keys(val)[0];
            const userData = val[key];
            userData.key = key;

            if (userData) {
              const snapAssetsGlobal = await firebase.db.ref('assets/global').once('value');
              const valAssetsGlobal = snapAssetsGlobal.val();

              const snapAssetsEnabled = await firebase.db.ref(`assets/${userData["codEscola"]}`).once('value');
              const valAssetsEnabled = snapAssetsEnabled.val();

              const snapSchoolData = await firebase.db.ref(`cadastroEscola/${userData["codEscola"]}`).once('value');
              const valSchoolData = snapSchoolData.val();

              const assetsKeys = Object.keys(valAssetsGlobal);

              const filteredAssetsKeys = assetsKeys.filter((k) => {
                return !!(valAssetsEnabled[k]);
              });

              const filteredAssetsList = [];

              for (let i = 0; i < filteredAssetsKeys.length; i++) {
                filteredAssetsList.push(valAssetsGlobal[filteredAssetsKeys[i]]);
              }

              // console.log(filteredAssetsList);

              userData["assetsList"] = filteredAssetsList;
              // userData["assetsAvailable"] = valAssetsEnabled;
              
              userData["schoolData"] = valSchoolData;

              this.setState({ authUser, userData });
            } else {
              this.setState({ authUser: null, userData: null });
            }
          } else {
            this.setState({ authUser: null, userData: null });
          }
        } else {
          this.setState({ authUser: null, userData: null });
        }
      });
    }

    render() {
      const { authUser, userData } = this.state;

      return (
        <AuthUserContext.Provider value={{ authUser, userData }}>
          <Component />
        </AuthUserContext.Provider>
      );
    }
  };

export default withAuthentication;
