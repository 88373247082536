import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { Provider } from 'react-redux'
// import store from './store'

ReactDOM.render(
  // "/imaginaweb"
  <BrowserRouter basename="/">
    {/* <Provider store={store}> */}
      <App />
    {/* </Provider> */}
  </BrowserRouter>
, document.getElementById('root'));

serviceWorker.unregister();