import React from 'react';
import Navbar from './shared/Navbar';

// import { Container } from './styles';

const Layout = (props) => {

    const navbar = !props.isFullPageLayout ?
        <Navbar {...props} location={props.location} authData={props.authData} /> :
        null;

  return (
      <>
      {navbar}
        <div className="page-body-wrapper full-page-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
                {props.children}
            </div>
          </div>
        </div> 
    </> 
  );
}

export default Layout;