import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyDsp-keYU3QyOc207jnNr424sdVlQgLmvo",
    authDomain: "startupweekend-175921.firebaseapp.com",
    databaseURL: "https://imaginakids-app.firebaseio.com",
    // databaseURL: "https://startupweekend-175921.firebaseio.com/",
    projectId: "startupweekend-175921",
    storageBucket: "imagina-drawings",
    messagingSenderId: "650196234622"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }

const auth = firebase.auth();
const db = firebase.database();
const storage = firebase.storage();

export {
  db,
  auth,
  storage,
};