import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthUserContext from './auth/AuthUserContext';
import Layout from './Layout';
import BookCatalog from './screens/BookCatalog';
// import Footer from './shared/Footer';
// import Sidebar from './shared/Sidebar';

import Spinner from './shared/Spinner';
// import StudentScreen from './screens/StudentScreen';
// import PageEditScreen from './screens/PageEditScreen';

// import { connect } from 'react-redux'
// import { createStory } from './store/actions'

// const Dashboard = lazy(() => import('./screens/Dashboard'));

const PageEditor = lazy(() => import('./screens/PageEditor'));

// const ClassScreen = lazy(() => import('./screens/ClassScreen'));

// const Buttons = lazy(() => import('./basic-ui/Buttons'));
// const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
// const Typography = lazy(() => import('./basic-ui/Typography'));

// const BasicElements = lazy(() => import('./form-elements/BasicElements'));

// const BasicTable = lazy(() => import('./tables/BasicTable'));

// const FontAwesome = lazy(() => import('./icons/FontAwesome'));


// const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./screens/Error404'));
const Error500 = lazy(() => import('./screens/Error500'));

const Login = lazy(() => import('./screens/Login'));
// const Register1 = lazy(() => import('./screens/Register'));

const BlankPage = lazy(() => import('./screens/BlankPage'));


class AppRoutes extends Component {

  render () {
    return (
      <AuthUserContext.Consumer>
      {authData =>
        authData == null || authData.authUser == null ?
        <div className="container-scroller">
        <div className="page-body-wrapper full-page-wrapper">
          <div className="main-panel">
            <div className="content-wrapper ">
              <Suspense fallback={<Spinner/>}>
                <Switch>
                  <Route path="/login" component={() => <Login authUser={authData.authUser}/>} />

                  <Redirect to="/login" />
                </Switch>
              </Suspense>
              </div>
            </div>
          </div>
        </div> :
        <div id="main-container" className="container-scroller">
        
              <Suspense fallback={<Spinner/>}>
                <Switch>

                  <Route exact path="/catalog"
                    location={this.props.location}
                    render={(props) => (
                      <Layout {...props} isFullPageLayout={this.props.isFullPageLayout} authData={authData}>
                        <BookCatalog {...props} authData={authData} />
                      </Layout>
                    )}
                  />
                  
                  <Route exact path="/book/:studentId/:pageNum"
                    location={this.props.location}
                    render={(props) => (
                      <Layout {...props} isFullPageLayout={this.props.isFullPageLayout} authData={authData}>
                        <PageEditor {...props} authData={authData}/>
                      </Layout>
                    )}
                  />

                  <Route path="/error-404" component={ Error404 } />
                  <Route path="/error-500" component={ Error500 } />

                  <Route path="/blank-page" component={ BlankPage } />

                  {
                    (authData.userData && authData.userData["turma"] === "Professor" && authData.userData["turmaProfessor"]) ?
                      <Redirect to={`/catalog`} /> :
                      <Redirect to={`/book/${authData.userData.key}/0`} />
                  }
                </Switch>
              </Suspense>
              </div>
            
      }
      </AuthUserContext.Consumer>
    );
  }
}

AppRoutes.contextType = AuthUserContext;

// export default connect(
//   null,
//   { createStory }
// )(AppRoutes)

export default AppRoutes;